import { Link } from "gatsby"
import React from "react"
import useFooterLinksQuery from "../../../graphql/useFooterLinksQuery"
const Footer = () => {
  const footerItems = useFooterLinksQuery()
  return (
    <footer className="footer footer-center relative rounded p-10 text-xl text-base-content">
      <div className="absolute -right-6 z-[0]  h-32 w-32 rounded-full bg-base-300"></div>
      <div className="z-10 flex w-full flex-row flex-wrap items-center justify-between gap-5">
        <div className=" flex flex-wrap gap-4 text-lg">
          {footerItems.map((item, index) => {
            if ((item.type = "INTERNAL")) {
              return (
                <Link
                  key={`footer-${index}`}
                  to={`/${
                    item?.related?.slug ? item?.related?.slug : item.path
                  }`}
                  className="link "
                >
                  <span>{item.title}</span>
                </Link>
              )
            }
            if ((item.type = "EXTERNAL")) {
              return (
                <a key={`footer-${index}`} href={`${item.path}`}>
                  {item.title}
                </a>
              )
            }
          })}
        </div>
        <span>
          Vytvořila
          <a className=" ml-2  text-primary" href="https://anafra.cz/">
            ANAFRA s.r.o.
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
