import { Link } from "gatsby"
import React from "react"

const Logo = () => {
  return (
    <div>
      <Link to="/" className="flex items-center">
        {" "}
        <svg
          width="80"
          height="45"
          viewBox="0 0 80 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M58.4235 20.3771L51.5858 23.6822C51.4593 23.7433 51.3215 23.7771 51.1811 23.7814L13.5394 24.9309C13.3213 24.9375 13.1069 24.8727 12.9291 24.7461L6.87023 20.4346C6.28255 20.0164 6.31742 19.1326 6.93622 18.762L31.6525 3.95932C31.9617 3.77415 32.3466 3.76967 32.66 3.9476L58.4821 18.6071C59.183 19.005 59.1493 20.0263 58.4235 20.3771Z"
            className="fill-primary"
          />

          <rect
            x="16.8271"
            y="22"
            width="31.3452"
            height="18.0457"
            className="fill-secondary"
          />
          <path
            d="M50.0993 37.8587V24.7382C50.0993 22.3328 42.2771 20.3647 32.4994 20.3647C22.7216 20.3647 14.8994 22.3328 14.8994 24.7382V37.8587"
            className="stroke-secondary"
            strokeWidth={4.5}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.0997 25.3944L61.7768 21.0973C62.5702 20.8053 62.6658 19.7217 61.9358 19.2953L34.5171 3.2817C33.2707 2.55377 31.7288 2.55377 30.4824 3.2817L3.06375 19.2953C2.33371 19.7217 2.42932 20.8053 3.22273 21.0973L14.8998 25.3944M2.85641 19.6551V32.4811M32.4998 33.1489C22.7807 33.1489 14.8998 35.106 14.8998 37.5224C14.8998 39.9387 22.7807 41.8959 32.4998 41.8959C42.2188 41.8959 50.0997 39.9387 50.0997 37.5224C50.0997 35.106 42.2188 33.1489 32.4998 33.1489Z"
            className="stroke-secondary"
            strokeWidth={4.5}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3478 1.33888C31.2953 0.201493 33.7045 0.201491 35.6519 1.33888L63.0563 17.3441C65.4368 18.7344 65.1141 22.2708 62.5213 23.2073L52.9796 26.6536L51.4509 22.4212L58.4797 19.8825L33.3824 5.22469C32.8372 4.90622 32.1626 4.90622 31.6173 5.22469L6.49334 19.8981L13.5139 22.4733L11.9642 26.698L5.10653 24.1826V34.7312H0.606527V21.5005C-0.0253299 20.0833 0.377202 18.2589 1.93161 17.3511L29.3478 1.33888Z"
            className="fill-primary"
          />
        </svg>
        <span className="py-2 text-xl font-bold md:text-4xl">
          Základní Škola Anafra
        </span>
      </Link>
    </div>
  )
}

export default Logo
