import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { AiOutlineMenu } from "react-icons/ai"
import { AiOutlineClose } from "react-icons/ai"
import useOnClickOutside from "use-onclickoutside"
import FlatSideMenu from "../sidemenu/FlatSideMenu"
import SidePanel from "../sidepanel/SidePanel"

const HamburgerNavTop = ({ activeSlug }: { activeSlug?: string }) => {
  const [drawer, setDrawer] = useState(false)
  const ref = React.useRef(null)
  useOnClickOutside(ref, () => {
    setDrawer(false)
  })

  return (
    <div ref={ref}>
      <div
        className={`btn btn-primary  ml-4 md:hidden`}
        onClick={() => {
          setDrawer(!drawer)
        }}
      >
        <AiOutlineMenu />
      </div>
      <AnimatePresence>
        {drawer && (
          <div>
            <div className=" absolute left-0  z-30 mt-4 w-full bg-base-100">
              <div className="mr-3 flex w-full justify-end pt-4">
                <FlatSideMenu activeSlug={activeSlug} />
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HamburgerNavTop
