import { Link } from "gatsby"
import React from "react"
import { NavigationTreeItem } from "../../../interfaces/Navigations"
import { RiArrowDropDownFill } from "react-icons/ri"
import { Popover } from "@headlessui/react"
import NavBarItem from "./NavBarItem"
const NavBarItemDropDown = ({
  item,
  activeSlug,
}: {
  item: NavigationTreeItem
  activeSlug?: string
}) => {
  return (
    <Popover as="div" className="relative ">
      <Popover.Button className="link link-hover inline-flex  flex-nowrap items-center px-4 py-2">
        {item.title}
        <RiArrowDropDownFill />
      </Popover.Button>
      <Popover.Panel className=" rounded-box absolute z-30 mt-4 flex flex-col  flex-nowrap space-y-2 whitespace-nowrap  bg-base-100 p-5  text-left shadow-md">
        {item.items &&
          item?.items.map((subitem) => {
            return (
              <NavBarItem
                className="justify-start text-left"
                item={subitem}
                activeSlug={activeSlug}
              />
            )
          })}
      </Popover.Panel>
    </Popover>
  )
}

export default NavBarItemDropDown
