import { Dialog } from "@headlessui/react"
import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { FiSearch } from "react-icons/fi"
import { useFlexSearch } from "react-use-flexsearch"
import { SearchItem } from "../../../interfaces/Universal"
import NavBarItem from "../navbar/NavBarItem"
import { AiOutlineClose } from "react-icons/ai"

const FloatSearchBar = () => {
  let [isOpen, setIsOpen] = useState(false)
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)

  const [searchQuery, setSearchQuery] = useState("")
  const results = useFlexSearch(
    searchQuery,
    queryData.localSearchPages.index,
    queryData.localSearchPages.store
  )
  return (
    <>
      <div className="ml-8">
        <button
          className="btn "
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <FiSearch />
        </button>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed inset-0 z-[100] overflow-y-auto "
      >
        <div className="flex min-h-screen items-start justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="card relative mx-auto mt-[10vw] w-full max-w-lg bg-base-100 shadow-lg">
            <div className="card-body">
              <div className="flex flex-nowrap gap-5">
                <input
                  type="search"
                  className="input input-bordered w-full text-lg"
                  placeholder="Hledat..."
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <button
                  className="btn btn-error justify-end"
                  onClick={() => setIsOpen(false)}
                >
                  <AiOutlineClose />
                </button>
              </div>
              {!results ||
                (results.length === 0 && (
                  <p>Do vyhledávajcího pole zadejte dotaz.</p>
                ))}
              {results && results.length > 0 && (
                <div className="z-50 mt-5 flex h-full w-full flex-col gap-y-2">
                  <div className="card-title">Výsledky vyhledávání: </div>
                  {results.map((res: SearchItem) => {
                    return (
                      <NavBarItem
                        key={res.slug}
                        className=" w-full justify-start text-left"
                        item={{
                          title: res.title,
                          slug: res.slug,
                          path: res.path,
                          related: { slug: res.path },
                          type: "INTERNAL",
                          menuAttached: false,
                        }}
                        activeSlug=""
                      />
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default FloatSearchBar
