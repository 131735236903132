import React from "react"
import useNavigationItemsQuery from "../../../graphql/useNavigationItemsQuery"
import NavBarItem from "./NavBarItem"
import NavBarItemDropDown from "./NavBarItemDropDown"

const NavBarMenu = ({ activeSlug }: { activeSlug?: string }) => {
  const navigationItems = useNavigationItemsQuery()
  return (
    <ul className="menu menu-horizontal w-full  flex-wrap gap-2 text-xl">
      {navigationItems &&
        navigationItems.map((navItem) => (
          <>
            {(navItem.items?.length === 0 || !navItem?.items) && (
              <NavBarItem item={navItem} activeSlug={activeSlug} />
            )}
            {navItem.items && navItem.items?.length > 0 && (
              <NavBarItemDropDown item={navItem} activeSlug={activeSlug} />
            )}
          </>
        ))}
    </ul>
  )
}

export default NavBarMenu
