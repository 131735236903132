import { Link } from "gatsby"
import React from "react"
import { NavigationTreeItem } from "../../../interfaces/Navigations"

const NavBarItem = ({
  item,
  activeSlug,
  className,
}: {
  item: NavigationTreeItem
  activeSlug?: string
  className?: string
}) => {
  const itemPath = item?.related?.slug ? `/${item.related.slug}` : item.path
  const itemSlug = item?.related?.slug ? item.related.slug : item.path

  return (
    <Link
      className={`${className} link link-hover w-auto px-4 py-2 ${
        itemSlug === activeSlug ? "link-primary underline" : ""
      }`}
      to={itemPath}
    >
      {item.title}
    </Link>
  )
}

export default NavBarItem
