import { Disclosure } from "@headlessui/react"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri"
import useNavigationItemsQuery from "../../../graphql/useNavigationItemsQuery"
import NavBarItem from "../navbar/NavBarItem"
import MenuItem from "./MenuItem"

const SideMenu = ({ activeSlug }: { activeSlug?: string }) => {
  const navigationItems = useNavigationItemsQuery()
  console.log(navigationItems)

  return (
    <div
      className="
    w-full text-xl shadow-xl"
    >
      {navigationItems &&
        navigationItems.map((navItem) => {
          // TODO
          const disclosureOpen = navigationItems.some(
            (arr) => arr.related?.slug !== activeSlug
          )
          return (
            <ul key={`parent-${navItem.slug}`} className="menu">
              {(navItem.items?.length === 0 || !navItem?.items) && (
                <NavBarItem
                  key={navItem.slug}
                  item={navItem}
                  activeSlug={activeSlug}
                />
              )}
              {navItem.items && navItem.items?.length > 0 && (
                <div>
                  <span className="py-4 pl-4 text-base text-neutral-focus">
                    {navItem.title}
                  </span>

                  <ul className="menu">
                    {navItem.items &&
                      navItem.items.map((subitem) => {
                        return (
                          <NavBarItem
                            key={subitem.slug}
                            item={subitem}
                            activeSlug={activeSlug}
                          />
                        )
                      })}
                  </ul>
                </div>
              )}
            </ul>
          )
        })}
    </div>
  )
}

export default SideMenu
