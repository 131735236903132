import React from "react"
import { NavigationTreeItem } from "../../interfaces/Navigations"
import Footer from "../Layout/footer/Footer"
import HamburgerNav from "../Layout/HamburgerNav/HamburgerNav"
import HamburgerNavTop from "../Layout/HamburgerNav/HamburgerNavTop"
import HeaderLinks from "../Layout/Header/HeaderLinks"
import ImageBar from "../Layout/ImageBar"
import NavBarMenu from "../Layout/navbar/NavBarMenu"
import FloatSearchBar from "../Layout/SearchBar/FloatSearchBar"
import SidePanel from "../Layout/sidepanel/SidePanel"
import Logo from "../Logo/Logo"
import LogoSmall from "../Logo/LogoSmall"

const TopNavigationLayout = ({
  children,
  activeItem,
  activeSlug,
}: {
  children: React.ReactNode
  activeItem?: NavigationTreeItem
  activeSlug?: string
}) => {
  return (
    <>
      <div className="sticky top-0 z-30 w-full bg-base-100 shadow-md">
        <div className="container  mx-auto  ">
          <div className="navbar top-0 right-0 z-30 justify-between  px-4  md:py-6 ">
            <div className="justify-star w-1/4 shrink">
              <Logo />
            </div>
            <div className="w-auto justify-center">
              <div className="hidden md:block">
                <NavBarMenu activeSlug={activeSlug} />
              </div>
              <FloatSearchBar />
              <div className="block md:hidden ">
                <HamburgerNavTop activeSlug={activeSlug} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ImageBar /> */}
      <div className="container mx-auto min-h-[60vh] px-4  ">
        <div className="relative mt-10 mb-20">{children}</div>
      </div>
      <div className="w-full bg-base-200 pt-10 pb-10 shadow-inner">
        <div className="container mx-auto ">
          <Footer />
        </div>
      </div>
    </>
  )
}

export default TopNavigationLayout
